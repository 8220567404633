<template>
    <div class="admin-content">
        <h3>안내 템플릿 관리</h3>
        <div class="flex-wrap mb30 right">
            <div class="sch-wrap3">
                <div>
                    <label>채용공고</label>
                    <div class="select-box form-flex-auto form-w-auto">
                        <select v-model="state.selectedSearchRecruitment" @change="methods.getNoticeOptions()">
                            <option v-for="item in state.dataTable.searchRecruitmentList" v-bind:value="item.Seq" v-bind:key="item" v-text="item.Title" />
                        </select>
                    </div>
                </div>
                <div>
                    <label>전형절차</label>
                    <div class="select-box form-flex-auto form-w-auto">
                        <select v-model="state.selectedSearchStepType" @change="methods.getAnnounceTemplate()">
                            <option value="">전체</option>
                            <option v-for="item in state.dataTable.searchStepTypeList" v-bind:value="item.EXAM_DIV_CD" v-bind:key="item" v-text="item.EXAM_DIV_NM" />
                        </select>
                    </div>
                </div>
                <div class="ml10">
                    <div class="btn-wrap">
                        <button type="button" class="btn-box" @click="methods.getAnnounceTemplate()">조회</button>
                    </div>
                    <div class="btn-wrap ml10">
                        <button type="button" class="btn-box" @click="methods.setAnnouncementContent()">저장</button>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="flex-wrap half">
            <div class="col-5">
                <DataTable
                    class="p-datatable-sm"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :paginator="true"
                    :rowHover="true"
                    :rows="10"
                    :value="state.dataTable.announcementList">
                    <Column field="EXAM_DIV_NM" header="전형절차" :sortable="true" style="width:30%">
                        <template #body="{ data }">
                            <div class="a-hover stepStyle" @click="methods.getAnnounceTemplateDetail(data, $event)">
                                <a href='javascript:void(0);'>{{ data.EXAM_DIV_NM }}</a>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="col-7">
                <SmartEditor ref="smartEditor" :contents="state.editorContent" :isClear="state.isClear" :subUrl="'/Admin/'"/>
            </div>
        </div>
    </div>
</template>
<style>
    .selectedStep {font-weight:600;}
</style>
<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from "vue-router";
import { recruitmentPassManagement } from '@/modules/recruit/REC_RC0400R.js';
import { announcementManagement } from '@/modules/recruit/REC_RC0500R.js';
import alertManager from '@/utils/alert-confirm-manager';
import SmartEditor from '@/components/SmartEditor.vue';
import he from 'he';
export default {
    components: { SmartEditor },
    setup() {

        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 스마트에디터
        const smartEditor = ref(null);

        // state
        const state = reactive({

            parameter: {

                Seq: '',

                EXAM_DIV_CD: ''
            },

            dataTable: {

                // 공고 목록
                searchRecruitmentList: new Array(),

                // 전형구분 목록
                searchStepTypeList: new Array(),

                // 안내 템플릿 목록
                announcementList: new Array(),

                // 안내 템플릿 내용
                announcementDetail: new Array()
            },
           
            // 선택된 공고(검색)
            selectedSearchRecruitment: 0,

            // 선택한 전형구분(검색)
            selectedSearchStepType: '',

            // 에디터 내용
            editorContent: '',

            // 에디터 내용 삭제
            isClear: 'Y'
        })

        // 메서드
        const methods = {
            
            // 채용 공고 조회
            getNotice: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                recruitmentPassManagement.getNotice('RECCM001').then(result => {

                    state.dataTable.searchRecruitmentList = result.Table.rows

                }).finally(() => {
                    if(state.dataTable.searchRecruitmentList.length != 0){

                        state.selectedSearchRecruitment = state.dataTable.searchRecruitmentList[0]['Seq']
                        
                        methods.getNoticeOptions()
                    }
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                });
            },

            // 지원 분야 조회
            getNoticeOptions: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                // 템플릿명, 내용 빈화면 세팅
                state.editorContent = ''

                recruitmentPassManagement.getNoticeOptions(state.selectedSearchRecruitment.toString()).then(result => {

                    state.dataTable.searchStepTypeList = []
                    let step =  result.Table1.rows.filter(step => step.USE_YN === 'Y')

                    step.push({
                        EXAM_DIV_CD: "RC004099",
                        EXAM_DIV_NM: "불합격",
                        PREST_END_DA: "",
                        PREST_ST_DA: "",
                        SORT_SEQ: 8,
                        Seq: state.selectedSearchRecruitment,
                        SystCode: "ADM",
                        USE_YN: "Y"})

                    state.dataTable.searchStepTypeList = step

                    if(step == '') state.dataTable.announcementList = []

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                    state.selectedSearchStepType = ''
                
                    methods.getAnnounceTemplate()
                });
            },

            // 합격자 안내 문구 목록 조회
            getAnnounceTemplate: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                state.parameter.Seq = state.selectedSearchRecruitment.toString()
                state.parameter.EXAM_DIV_CD = state.selectedSearchStepType

                announcementManagement.getAnnounceTemplate(JSON.stringify(state.parameter)).then(result => {

                    smartEditor.value.setClear();

                    // 안내 문구 목록 설정
                    state.dataTable.announcementList = result.Table.rows

                    if(result.Table.rows.length == 1){
                        state.parameter.EXAM_DIV_CD = result.Table.rows[0].EXAM_DIV_CD
                        announcementManagement.getAnnounceTemplateDetail(JSON.stringify(state.parameter)).then(result => {
                            
                            smartEditor.value.setClear();
                            
                            if(!!result){

                                state.dataTable.announcementDetail = result[0]['ANNC_TMPL_CONT']
                                state.editorContent = state.dataTable.announcementDetail
                                smartEditor.value.state.oEditors.getById['smartEditor'].exec('PASTE_HTML', [state.editorContent]);
                            } 
                        })
                    }

                }).finally(() => {

                    // 전형절차 선택 색상 초기화
                    let step = document.querySelectorAll('.stepStyle');
                    for(let elem of step) {
                        elem.classList.remove('selectedStep');
                    }

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                });
            },

            // 안내 문구 상세 조회
            getAnnounceTemplateDetail: (data, $event) => {
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                // 선택한 전형절차 색상 표시
                let step = document.querySelectorAll('.stepStyle')

                for(let elem of step) {
                    elem.classList.remove('selectedStep')
                }

                $event.currentTarget.classList.add('selectedStep')

                state.parameter.EXAM_DIV_CD = data.EXAM_DIV_CD

                announcementManagement.getAnnounceTemplateDetail(JSON.stringify(state.parameter)).then(result => {
                    
                    
                    // 상세 조회 전 스마트에디터 컨텐츠 초기화
                    //smartEditor.value.state.oEditors.getById['smartEditor'].exec('SET_IR', [""]);
                    smartEditor.value.setClear();
                    
                    if(!!result){

                        state.dataTable.announcementDetail = result[0]['ANNC_TMPL_CONT']
                        state.editorContent = state.dataTable.announcementDetail
                        smartEditor.value.state.oEditors.getById['smartEditor'].exec('PASTE_HTML', [state.editorContent]);
                    } else {

                        state.editorContent = ''
                    }
                    
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                })
            },

            // 안내 문구 상세 조회
            getAnnounceTemplateDetail2: (data) => {
                
                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                state.parameter.EXAM_DIV_CD = data.EXAM_DIV_CD

                announcementManagement.getAnnounceTemplateDetail(JSON.stringify(state.parameter)).then(result => {
                    
                    smartEditor.value.setClear();
                    
                    if(!!result){

                        state.dataTable.announcementDetail = result[0]['ANNC_TMPL_CONT']
                        state.editorContent = state.dataTable.announcementDetail
                        smartEditor.value.state.oEditors.getById['smartEditor'].exec('PASTE_HTML', [state.editorContent]);
                    } 
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)
                })
            },
    
            // 합격자 안내문구 저장
            setAnnouncementContent: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                let announcementContent = new Object() 

                announcementContent = {

                    'Seq': state.selectedSearchRecruitment.toString(),
                    'EXAM_DIV_CD': state.parameter.EXAM_DIV_CD,
                    'ANNC_TMPL_CONT': window.btoa(encodeURIComponent((he.escape(smartEditor.value.getContents()))))
                }

                let announcement = JSON.stringify(announcementContent)

                announcementManagement.setAnnouncementContent(announcement).then((response) => {

                    if(response.success) {
                        
                        alertManager.alert.success('저장 되었습니다.')
                    }
                
                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            }
        }

        onMounted(() => {

            methods.getNotice();
        })

        return { state, methods, smartEditor, recruitmentPassManagement, announcementManagement }
    }
}
</script>