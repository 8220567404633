import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 팝업정보
 */
 class AnnouncementManagement {
    /**
     * 생성자
     */
    constructor() {

    }

    /**
     * 채용공고 조회
     * @param {any} seq
     */
    getNotice(boardCategory) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetNotice';
            request.parameters.boardCategory = boardCategory;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    /**
     * 합격자 안내 문구 조회
     * @returns 
     */
    getAnnounceTemplateInfo() {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetAnnounceTemplateInfo';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;
                
                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 합격자 조회
     * @param {any} Seq
     * @param {any} APCTP_KR_NM
     * @param {any} APCT_PT_CD
     * @param {any} EXAM_DIV_CD
     * @param {any} ANNOUNCE_YN
     * @returns 
     */
    getPassApplicantList(Seq, APCTP_KR_NM, APCT_PT_CD, EXAM_DIV_CD, ANNOUNCE_YN) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetPassApplicantList';
            request.parameters.Seq = Seq;
            request.parameters.APCTP_KR_NM = APCTP_KR_NM;
            request.parameters.APCT_PT_CD = APCT_PT_CD;
            request.parameters.EXAM_DIV_CD = EXAM_DIV_CD;
            request.parameters.ANNOUNCE_YN = ANNOUNCE_YN;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 합격자별 안내 문구 조회
     * @param {any} dic
     * @returns 
     */
    getPassApplicantAnnouncement(dic) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetPassApplicantAnnouncement';
            request.parameters.dic = dic;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 프로젝트 정보 등록
     * @param {Any} detail 
     * @returns 
     */
    setApplicantAnnouncement(applicant) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz'
            request.methodId = 'SaveApplicantAnnouncement'
            request.parameters.applicant = applicant

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }

     /**
     * 안내 템플릿 내용 수정
     * @param {Any} dic 
     * @returns 
     */
    setAnnouncementContent(dic) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz'
            request.methodId = 'SaveAnnouncementContent'
            request.parameters.dic = dic

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }

        /**
     * 안내 문구 리스트 조회
     * @returns 
     */
    getAnnounceTemplate(dic) {

        return new Promise((resolve, reject) => {
                
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetAnnounceTemplate';
            request.parameters.dic = dic
    
            service.execute(request).then(response => {
    
                const tables = response.data.result.value;
                    
                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

        /**
     * 안내 문구 디테일 조회
     * @returns 
     */
    getAnnounceTemplateDetail(dic) {

        return new Promise((resolve, reject) => {
                    
            const url = process.env.VUE_APP_ANONYMOUSE_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url, false);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetAnnounceTemplateDetail';
            request.parameters.dic = dic
        
            service.execute(request).then(response => {
        
                const rows = response.data.result.value.Table.rows;
                        
                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }
}

export const announcementManagement = new AnnouncementManagement();