import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 팝업정보
 */
 class RecruitmentPassManagement {
    /**
     * 생성자
     */
    constructor() {

    }

    /**
     * 채용공고 조회
     * @param {any} seq
     */
    getNotice(boardCategory) {
        return new Promise((resolve, reject) => {
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetNotice';
            request.parameters.boardCategory = boardCategory;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        })
    }

    /**
     * 모집지원분야 / 전형절차 항목 조회
     * @param {any} seq
     */
    getNoticeOptions(seq) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetNoticeOptions';
            request.parameters.seq = seq;
            
            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 합격자 관리 조회
     * @param {any} seq
     */
    getUserPassManagementList(dic) {
        return new Promise((resolve, reject) => {

            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz';
            request.methodId = 'GetUserPassManagementList';
            request.parameters.dic = dic;
            
            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    };

    /**
     * 프로젝트 정보 등록
     * @param {Any} detail 
     * @returns 
     */
    setApplicantPass(applicant) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.RecruitBiz'
            request.methodId = 'SaveApplicantPass'
            request.parameters.applicant = applicant

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }
}

export const recruitmentPassManagement = new RecruitmentPassManagement();